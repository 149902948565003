import React, { useEffect } from 'react'
const WebPage = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return

    window.location.href = '/'
  }, [])

  return <></>
}

export default WebPage
